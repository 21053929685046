<script>
import { Bar, mixins } from 'vue-chartjs'

export default {
    extends: Bar,
    mixins: [mixins.reactiveProp],
    props: [
        'chartData'
    ],

    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart({
        labels: this.chartData.labels,
        datasets: this.chartData.datasets,
        },
        {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: false,
                text: "Händlerumsatz"
            }
        })
    }
}
</script>